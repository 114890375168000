import axios from 'axios'
import { City, State } from '@/interfaces/address'

class AddressService {
  public ENDPOINT = process.env.VUE_APP_SITE_DOMAIN

  /**
   * Return all cities from static JSON.
   */
  cities(): Promise<City[]> {
    return axios.get('/cities.json')
      .then(response => response.data)
  }

  /**
   * Return all states from static JSON.
   */
  states(): Promise<State[]> {
    return axios.get('/states.json')
      .then(response => response.data)
  }

  findAddressByCEP(CEP) {
    return axios
      .get('https://viacep.com.br/ws/' + CEP + '/json/')
      .then((response) => response.data)
  }
}

export const addressService = new AddressService()

import { BasicSubAccountForm, FullSubAccountForm, IuguBank, IuguSubAccount } from '@/interfaces/iugu'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

export default class IuguService {
  private IUGU_ENDPOINT = process.env.VUE_APP_IUGU_ENDPOINT
  private IUGU_EXTRATO_ENDPOINT = 'https://extrato-iugu-dot-startmeup-218419.rj.r.appspot.com/'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  findInvoice(offerUrl: string, invoiceId: string) {
    return this.client
      .get(`${this.IUGU_ENDPOINT}/iugu/fatura/busca/${offerUrl}/${invoiceId}`)
      .then(response => response.data)
  }

  findSubAccount(offerUrl: string) {
    return this.client
      .get(`${this.IUGU_ENDPOINT}/iugu/subconta/busca/${offerUrl}`)
      .then(response => response.data)
      .catch(() => null)
  }

  newBasicSubAccount(form: BasicSubAccountForm): Promise<IuguSubAccount> {
    return this.client
      .post(`${this.IUGU_ENDPOINT}/iugu/subconta/nova/262762cc1dd376620bac51f128dea754`, { ...form })
      .then((response: AxiosResponse<IuguSubAccount>) => response.data)
  }

  newFullSubAccount(urlOffer: string, form: FullSubAccountForm): Promise<IuguSubAccount> {
    return this.client
      .post(`${this.IUGU_ENDPOINT}/iugu/subconta/verifica/pj/${urlOffer}`, { ...form })
      .then((response: AxiosResponse<IuguSubAccount>) => response.data)
  }

  updatePaymentMethods(urlOffer: string, allowedMethods: { metodos_pagamento: string[] }) {
    return this.client
      .put(`${this.IUGU_ENDPOINT}/iugu/subconta/metodo-pagamento/${urlOffer}`, { ...allowedMethods })
      .then((response: AxiosResponse<IuguSubAccount>) => response.data)
  }

  updateDunningSteps(urlOffer: string, steps: any) {
    return this.client
      .put(`${this.IUGU_ENDPOINT}/iugu/subconta/altera/gestao-cobranca/${urlOffer}`, { ...steps })
      .then((response: AxiosResponse<IuguSubAccount>) => response.data)
  }

  updateSettings(urlOffer: string, form: any) {
    return this.client
      .put(`${this.IUGU_ENDPOINT}/iugu/subconta/configura/${urlOffer}`, { ...form })
      .then((response: AxiosResponse<IuguSubAccount>) => response.data)
  }

  dunningSteps(urlOffer: string) {
    return this.client
      .get(`${this.IUGU_ENDPOINT}/iugu/subconta/gestao-cobranca/${urlOffer}`, { params: { teste: false } })
      .then((response) => response.data)
  }

  /**
   * Return all states from static JSON.
   */
  banks(): Promise<IuguBank[]> {
    return axios.get('/iugu-banks.json')
      .then(response => response.data)
  }

  subAccountExtract({ urlOferta, inicio, limite, ano }) {
    return this.client
      .get(`${this.IUGU_EXTRATO_ENDPOINT}/extrato/${urlOferta}`, { params: { inicio, limite, ano } })
      .then((response) => response.data)
  }
}
























































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { addressService } from '@/services/Address.service'
import { City, State } from '@/interfaces/address'
import { Watch, Prop } from 'vue-property-decorator'
import OfferService from '@/services/Offer.service'
import { Offer, Sectors } from '@/interfaces/offer'
import { VueMaskDirective } from 'v-mask'

interface BasicOffer {
  nome_oferta: string;
  url_oferta?: string;
  site_oferta: string;
  cidade: string;
  estado: string;
  fundacao: string;
  cnpj: string;
  descricao: string;
  setores_atuacao: string[];
  solucao: string;
}

interface Errors {
  [key: string]: string;
}

@Component({
  name: 'offer-form',
  directives: {
    mask: VueMaskDirective
  }
})
export default class Basic extends Vue {
  @Prop({ required: false }) offer!: Offer

  offerService: OfferService = new OfferService()

  // Fix undefined reference
  Sectors = Sectors

  segments: { nome: string } = [] as any

  /**
   * Cities used with select element
   */
  cities = [] as City[]
  selectedCity: City | null = null

  /**
   * States to be used with select element.
   */
  states = [] as State[]
  selectedState: State | null = null

  selectedSectors: string[] = []

  /** Form with basic infos. */
  form = {} as BasicOffer

  errors = {} as Errors

  /**
   * Main form state.
   */
  formState: { [key: string]: string | boolean } = {
    loading: false
  }

  async mounted() {
    this.cities = await addressService.cities()
    this.states = await addressService.states()
    this.segments = await this.offerService.businessSegments()

    if (this.offer) {
      this.form = this.extractProperties(this.offer)

      this.selectedState = this.states.find(
        (s) => s.abbr === this.form.estado
      ) as State

      this.selectedCity = this.cities.find(
        (c) => c.name === this.form.cidade
      ) as City

      this.selectedSectors = this.form.setores_atuacao
    }
  }

  extractProperties(offer): BasicOffer {
    return {
      nome_oferta: offer.nome_oferta,
      url_oferta: offer.url_oferta,
      site_oferta: offer.site_oferta,
      cidade: offer.cidade,
      estado: offer.estado,
      fundacao: offer.fundacao,
      cnpj: offer.cnpj,
      descricao: offer.descricao,
      setores_atuacao: offer.setores_atuacao,
      solucao: offer.solucao
    }
  }

  @Watch('selectedCity')
  onChildChanged(val: City) {
    if (!val) {
      this.selectedState = null
      return
    }

    const state: State = this.states.find(
      (s: State) => s.id === val.state_id
    ) as State

    this.selectedState = state
  }

  /**
   * Handles form submit.
   */
  onFormSubmit() {
    this.updateOffer()
  }

  updateOffer() {
    this.formState.loading = true

    const sectors: string[] = this.selectedSectors

    const updateFields = {
      // url_oferta: slug(this.form.nome_oferta || ''),
      cidade: this.selectedCity?.name,
      estado: this.selectedState?.abbr,
      setores_atuacao: sectors
    }

    const form: BasicOffer = { ...this.form, ...updateFields } as BasicOffer
    const errors: Errors = this.validateBasicForm(form)
    this.errors = errors

    if (Object.keys(errors).length > 0) {
      this.formState.loading = false
      return
    }

    const offer = Object.assign(this.offer, form)

    this.offerService
      .update(offer)
      .then(() => {
        this.$notify({
          type: 'success',
          title: 'Concluído',
          text: `A oferta ${this.offer.nome_oferta} foi atualizada com sucesso.`,
          group: 'form'
        })
      })
      .catch((request) => {
        const message = (request.response && request.response.data) || 'Erro ao atualizar oferta.'
        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })
      .then(() => {
        this.formState.loading = false
      })
  }

  /**
   * Apply rules to properties and retrieve errors.
   */
  validateBasicForm(form: BasicOffer): Errors {
    const errors: { [key: string]: string } = {}

    if (!form.nome_oferta) {
      errors.nome_oferta = 'Nome da oferta é obrigatório.'
    }

    if (!form.site_oferta) {
      errors.site_oferta = 'Site da oferta é obrigatório.'
    }

    if (!form.cidade) {
      errors.cidade = 'Cidade é um campo obrigatório.'
    }

    if (!form.estado) {
      errors.estado = 'Estado é um campo obrigatório.'
    }

    if (!form.fundacao) {
      errors.fundacao = 'Data de Fundação da empresa é obrigatório.'
    }

    if (!form.cnpj) {
      errors.cnpj = 'CNPJ da empresa é obrigatório.'
    }

    if (!form.descricao) {
      errors.descricao = 'Descrição da empresa é obrigatório.'
    }

    const allSectorsValid =
      form.setores_atuacao &&
      form.setores_atuacao.length &&
      form.setores_atuacao.every((s) => !!s)

    if (!form.setores_atuacao || !allSectorsValid) {
      errors.setores_atuacao = 'Setor de atuação é um campo obrigatório.'
    }

    return errors
  }
}
